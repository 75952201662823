body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  margin-top: 4vw;
  margin-bottom: 4vw;
}

.spacing {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Reset CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Underline + hr */
.highLight1,
hr {
  background: linear-gradient(120deg, #e4a0a1 0%, #e4a0a1 100%);
  background-repeat: no-repeat;
  background-size: 100% 7%;
  background-position: 0 90%;
}

span {
  font-size: clamp(0.9rem, calc(12px + 0.36vw), 1.012rem);
  line-height: 1.1;
}


.bg-light {
 background-color: none;
}

.nav-link.active {
  outline: none;
  text-decoration: underline!important;
  text-decoration: green;
}

.nav-link:focus, .nav-link:hover {
  outline: none;
  color: none;
}


#Second[aria-expanded="true"]{
  text-decoration:line-through;
}

#test[aria-expanded="true"]{
  text-decoration:line-through;
}

#First[aria-expanded="true"]{
  text-decoration:line-through;
}


