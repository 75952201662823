.accordion .accordion-button .accordion-body {
  margin: 0;
  padding: 0 !important;
  background-color: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;

  padding: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
button.accordion-button {
  padding: 0 0 1px 0;
  background-color: transparent;
}

button.accordion-button.collapsed {
  background-color: transparent;
  box-shadow: none;
}

.accordion-item {
  padding-top: 5px;
  background-color: transparent;
  border: none;
}

.accordion-collapse {
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: transparent;
  background-color: none !important;
  box-shadow: none;
  width: auto !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-button:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button {
  width: auto !important;
}
