/*
|----------------------------------------------------------------------------
	7. ABOUT / #about
|----------------------------------------------------------------------------

#about {
	padding: 70px 0;
}
*/
#about .about-text .title-box {
    margin: 0 0 40px;
}
#about .title-box {
    margin: 10px 0 40px;
}
.about-text p {
    color: #505050;
    margin: 0 0 30px;
    padding: 0;
}
.experience-content p {
    color: #444;
}
.experience-content p.text-bold {
	color: #222;
	font-weight: 500;
    font-style: italic;
    margin: 0 0 20px;
}
#about .button-holder .btn.btn-shutter-out-horizontal:last-child {
    background-color: transparent;
    border: 1px solid #3e3e3e;
    color: #000;
}
#about .button-holder .btn.btn-shutter-out-horizontal:last-child::before {
    background-color: #04b962;
}
#about .button-holder .btn.btn-shutter-out-horizontal:last-child:hover {
    color: #fff;
    border-color: #04b962;
}
#about .button-holder {
    margin: 50px 0;
}
#about .button-holder a {
    margin: 0 20px 10px 0;
}
#about .button-holder a:last-child {
    margin-right: 0;
}	
#about .icon-holder .tab-content-title {
    font-weight: bold;
    left: 50%;
    margin-left: 45px;
    position: absolute;
    top: 10px;
	font-size: 24px;
}
#about .icon-holder {
    height: 64px;
    position: relative;
    text-align: center;
}
#about .icon-holder i {
    left: 50%;
    margin-left: -32px;
    position: absolute;
    top: 0;
    border: 1px solid #04b962;
    border-radius: 50%;
    color: #04b962;
    font-size: 32px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    width: 64px;
}
#about .profile-image {
    border: 2px solid rgba(4, 185, 98, 0.9);
    border-radius: 10px;
	margin: 0 0 50px;
    max-width: 480px;
    overflow: hidden;
}
#about .profile-image > img {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition:all 0.8s ease 0s;
    -moz-transition:all 0.8s ease 0s;
    -ms-transition:all 0.8s ease 0s;
    -o-transition:all 0.8s ease 0s;
    transition:all 0.8s ease 0s;
}
#about .profile-image > img:hover {
    -moz-transform: scale(1.1);	
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);	
}

.about-timeline-style .nav.nav-tabs, .about-box-style .nav.nav-tabs {
    max-width: 606px;
    margin: 50px auto;
	border: none;
	text-align: center;
}
.about-timeline-style .nav.nav-tabs > li, .about-box-style .nav.nav-tabs > li {
    margin: 0 0 10px;
}
.about-timeline-style .nav.nav-tabs > li button, .about-box-style .nav.nav-tabs > li button {
    background-color: #f0f0f0;
	cursor: pointer;
	padding: 10px 15px;
	border: none;
	margin-right: 2px;
    width: calc(100% - 2px);
}
.about-timeline-style .nav.nav-tabs > li button i, .about-box-style .nav.nav-tabs > li button i {
	color: #04b962;
    font-size: 20px;
    border: 1px solid;
    padding: 7px 5px 5px;
    border-radius: 50%;
    margin-right: 5px;
    width: 36px;
    height: 36px;
}
.about-timeline-style .nav.nav-tabs > li button span.tab-title, .about-box-style .nav.nav-tabs > li button span.tab-title {
    font-size: 18px;
    font-weight: bold;
}
.about-timeline-style .nav.nav-tabs > li.active button, .about-box-style .nav.nav-tabs > li.active button {
    background-color: #04b962;
    color: #fff;
}
.about-timeline-style .nav.nav-tabs > li.active button i, .about-box-style .nav.nav-tabs > li.active button i {
    color: #fff;
}



.timeline {
    list-style: none;
	margin: 0 0 50px;
    padding: 50px 0 0;
    position: relative;
}
.timeline:before {
	top: 0;
	bottom: 0;
	position: absolute;
	content: " ";
	width: 4px;
	background-color: #a84158;
	left: 50%;
	margin-left: -2px;
}




.timeline h4 {
	font-size: 20px;
	font-weight: 500;
	margin-top: 0;
}
.timeline h5 {
	/*color: #04b962;*/
    color:#a84158;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 20px;
}
.about-timeline-style .experience-content > p:last-child {
    margin: 0;
}
.timeline > li {
	margin: 0 0 50px;
	position: relative;
}
.timeline > li:before,
.timeline > li:after {
	content: " ";
	display: table;
}
.timeline > li:after {
	clear: both;
}
.timeline > li:before,
.timeline > li:after {
	content: " ";
	display: table;
}
.timeline > li:after {
	clear: both;
}
.timeline > li > .timeline-panel {
	background-color: #f0f0f0;
	width: 46%;
	float: left;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	padding: 30px;
	position: relative;
	-webkit-transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
    -ms-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
}
.timeline > li > .timeline-panel:hover {
	background-color: #fff;	
	border-color: #e5e5e5;
	box-shadow: 0 0 5px 1px rgba(62, 62, 62, 0.5);
}
.timeline > li > .timeline-panel:before {
	position: absolute;
	top: 25px;
	right: -16px;
	display: inline-block;
	border-color: transparent #f0f0f0;
    border-style: solid;
    border-width: 15px 0 15px 15px;
	content: "";
}
.timeline > li > .timeline-panel:after {
	border-color: transparent #f0f0f0;
    border-style: solid;
    border-width: 14px 0 14px 14px;
    content: "";
    display: inline-block;
    position: absolute;
    right: -14px;
    top: 27px;
}
.timeline-left .experience-content {
    text-align: right;
}
.timeline > li > .timeline-badge {
	font-size: 16px;
	color: #fff;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	position: absolute;
	top: 15px;
	left: 50%;
	margin-left: -26px;
	background-color: #04b962;
	z-index: 100;
	border-top-right-radius: 50%;
	border-top-left-radius: 50%;
	border-bottom-right-radius: 50%;
	border-bottom-left-radius: 50%;
}
.timeline > li.timeline-right > .timeline-panel {
	float: right;
}
.timeline > li.timeline-right > .timeline-panel:before {
	border-left-width: 0;
	border-right-width: 16px;
	left: -17px;
	right: auto;
}
.timeline > li.timeline-right > .timeline-panel:after {
	border-left-width: 0;
	border-right-width: 14px;
	left: -14px;
	right: auto;
}
.timeline li p.date {
	color: #000;
	font-size: 18px;
    left: 50%;
    margin: 0 50px;
    position: absolute;
    text-align: left;
    top: 12px;
	width: 180px;
	border-bottom: 1px dotted;
	line-height: 1.48;
}
.timeline li p.date-inverted {
	color: #000;
	font-size: 18px;
    margin: 0 50px;
    position: absolute;
    right: 50%;
    text-align: right;
    top: 12px;
	width: 180px;
	border-bottom: 1px dotted;
	line-height: 1.48;
}



/*
|----------------------------------------------------------------------------
	7.1. BOX STYLE / .about-box-style	MAY BE NOT NEED
|----------------------------------------------------------------------------
*/
/*


.about-box-style .experience-content {
	background-color: #f0f0f0;
	border-style: solid;
	border-color: #f0f0f0;
    border-width: 1px;
    border-radius: 5px 5px 20px;
    margin: 0 0 50px;
    overflow: hidden;
    padding: 30px 15px 20px;
    position: relative;
    width: 100%;
    text-align: center;
}
.about-box-style .experience-content:hover {
	background-color: #fff;
	border-color: #e5e5e5;
}
.about-box-style .experience-content .range {
	border-bottom: 3px dotted #222;
	color: #222;
	font-style: italic;
	margin: 0 auto 15px;
	text-align: center;
	width: 180px;
	padding-bottom: 5px;
}
.about-box-style .experience-content h4 {
	color: #222;
	font-size: 24px;
	font-weight: 500;
}
.about-box-style .experience-content h5 {
    color: #04b962;
	font-size: 16px;
	margin-bottom: 20px;
}
*/